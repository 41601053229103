.search_body{
    width: 600px;

}
.gamesearch_return_button{
    padding-top: 300px;
    color: black;

}
.game_search_icon{
    padding: 10px 10px 10px 10px;
    margin-top: 20px;
    margin-left: 20px;
    border-radius: 50%;
}
.game_search_icon:hover{
    background-color: rgb(219, 219, 219);
}
.gamesearch_img{
    width: 60px;
    height: 60px;
    border-radius: 10px;
}
.search_gameuser{
    margin-left: 20px;
}
@media screen and (max-width: 500px) {
    .search_body{
        width: 100%;
    }
}