.newprofile_body{
    width: 600px;
    padding-bottom: 200px;
    border-left: 0.5px solid rgb(197, 197, 197);
    border-right: 0.5px solid rgb(197, 197, 197);
}

/* ユーザー情報＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ */
.newprofile_userinfo{
    margin-left: 15px;
    margin-right: 10px;
    width: 580px;
}
/* 画像 */
.newprofile_userimage{
    width: 100px;
    height: 100px;
    border-radius: 50px;
}
.newprofile_userinfo_text{
    width: 560px;
    display: flex;
    justify-content: space-between;

}
.newprofile_username{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.newprofile_sns{
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
    border-bottom: 1px solid rgb(165, 165, 165);
}
/* 登録ボタン */
.regist{
    width: 100%;
    text-align: center;
    margin-top: 20px;
}
.twitch:hover { background-color:rgb(168, 108, 224)}
.discord:hover { background-color: rgb(129, 111, 212);}
.youtube:hover { background-color: rgb(255, 78, 78);}
.x-twitter:hover { background-color: rgb(87, 86, 86);}
.tiktok:hover { background-color: rgb(70, 69, 69);}
/* ゲーム一覧 */
.gamelist{
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
}
.newprofile_gamelist{
    display: flex;
    align-items: center;
    width: 500px;
    justify-content: space-between;
}
.gamelist img{
    width: 60px;
    height: 60px;
    border-radius: 13px;
}
.gamelist_gamename{
    font-size: 20px;
    font-weight: bold;
    margin-left: 20px;
}
/* 編集ボタン削除ボタンが丸になる */
/* .profile_game_delete_button{
    background-color: rgb(233, 233, 233);
    padding: 6px 6px;
    border-radius: 50%;
} */
/* .edit_icon{
    background-color: rgb(233, 233, 233);
    padding: 6px 6px;
    border-radius: 50%;
} */
.edit_button_regist{
    display: flex;
    align-items: center;
}
.edit_delete_button_tooltip{
    display: flex;
    align-items: center;
}
 .menuitems{
    display: flex;
    /* align-items: center; */
 }
.buttons{
    display: flex;
    align-items: center;
}

/* 自分のプロフィール */
.newprofile_game_info_text{
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}
.newprofile_name_text{
    background-color: rgb(150, 150, 150);
    color: white;
    padding: 5px 10px;
    text-align: center;
    border-radius: 15px;
    margin-right: 10px;
}
.newprofile_id_text{
    background-color: rgb(150, 150, 150);
    color: white;
    padding: 5px 18px;
    text-align: center;
    border-radius: 15px;
    margin-right: 10px;
}
.profile_copy_text{
    padding: 5px 18px;
    background-color: rgb(117, 189, 189);
    color: white;
    border-radius: 15px;
    cursor: pointer;
}
.profile_copy_text:hover{
    background-color: rgb(144, 211, 211);
}
.profile_followbutton a{
    background-color: rgb(0, 0, 0);    
    padding: 10px 20px;
    font-size: 13px;
    color: white;
    border-radius: 40px;
}
.profile_followbutton span{
    background-color: rgb(0, 0, 0);    
    padding: 10px 20px;
    font-size: 13px;
    color: white;
    border-radius: 40px;
}
/* ログインしていないユーザー */
.header_buttons{
    display: block;
    display: flex;
    position: fixed;
    top: 0;
    width: 600px;
    height: 6%;
    background-color: rgb(255, 255, 255);
    border-top: 1px solid rgb(228, 228, 228);
    z-index: 2;
    background-color: rgb(199, 199, 199);
    align-items: center;
    justify-content: space-between;
  }
.nouser_button{
    display: block;
    margin-right: 20px;
    color: white;
    padding: 10px 5px;
    background-color: black;
    border-radius: 20px;
    font-size: 12px;
}
.profile_rogo{
    display: block;
    width: 40px;
    height: 40px;
    margin-left: 10px;
}
.igdb {
    display: inline-block;
    color: black;
  }
  
  /* .igdb-text {
    display: block;
  } */
  
  .com-text {
    display: block;
  }
/* .igdb{
    color: black;
    padding: 6px 6px;
    background-color: rgb(223, 223, 223);
    font-size: 10px;
    max-width: 24px;
    border-radius: 40px;
} */

.edit_delete_icon{
    width: 60px;
    height: 60px;
    border-radius: 10px;
}
.user_check_follower_user{
    padding: 3px 5px;
    color: white;
    background-color: rgb(72, 163, 216);
    font-size: 12px;
}
.user_check_follow_user{
    padding: 3px 5px;
    color: white;
    background-color: rgb(228, 158, 54);
    font-size: 12px;
}
.user_check_friend_user{
    padding: 3px 5px;
    color: white;
    background-color: rgb(82, 201, 82);
    font-size: 12px;
}
.user_check{
    text-align: center;
    border-radius: 20px;
}
.gamelist{
    position: relative;
    z-index: 0;
}
.menu{
    z-index: 100;
}


@media screen and (max-width: 500px) {
    .newprofile_body{
        width: 100vh;
    }
    .another_profile_body{
        margin-top: 50px;

    }

    .newprofile_userinfo{

        margin-left: 15px;
        margin-right: 10px;
        width: 350px;
    }
    .newprofile_userimage{
        width: 80px;
        height: 80px;
        border-radius: 50px;
    }
    .newprofile_userinfo_text{
        width: 330px;
        display: flex;
        justify-content: space-between;
    
    }
    .regist{
        width: 100%;
        text-align: center;
        margin-top: 20px;
    }
    .gamelist{
        margin-top: 5px;
        margin-bottom: 5px;
        width: 100%;
    }
    .newprofile_gamelist{
        display: flex;
        align-items: center;
        width: 300px;
        justify-content: space-between;
    }
    .gamelist img{
        width: 60px;
        height: 60px;
        border-radius: 13px;
    }
    .header_buttons{
        display: block;
        display: flex;
        position: fixed;
        top: 0;
        width: 100%;
        height: 50px;
        background-color: rgb(255, 255, 255);
        border-top: 1px solid rgb(228, 228, 228);
        z-index: 2;
        background-color: rgb(199, 199, 199);
        align-items: center;
        justify-content: space-between;
      }
    .nouser_button{
        display: block;
        margin-right: 20px;
        color: white;
        padding: 10px 5px;
        background-color: black;
        border-radius: 20px;
        font-size: 12px;
    }
    .profile_rogo{
        display: block;
        width: 40px;
        height: 40px;
        margin-left: 10px;
    }
    .app_sidebar{
        z-index: 50;
    }
    .newprofile_body{
        z-index: 1;
    }
    .gamelist_gamename{
        font-size: 15px;
    }
    .gamelist img{
        width: 45px;
        height: 45px;
        border-radius: 13px;
    }
    .newprofile_username{
        font-size: 25spx;
    }

} 
