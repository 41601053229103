.appimg{
    width: 50px;
    height: 50px;
    border-radius: 10px;
}
.regist{
    margin-bottom: 2%;
}

.dialog3{
    display: flex;
}

.gamename{
    padding-top: 20px;
    padding-left: 20px;
}

.mobile_or_pc{
    display: flex;
}

/* .iphoneicon{
    margin-top: 25px;
} */

.iphoneicon{
    margin-left: 20px;
}

.pcgameicon{
    margin-top: 10px;
    margin-left: 20px;

}

.mobile_game_select{
    width: 150px;
    border: 1px solid black;
    border-radius: 20px;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;

}

.mobile_game_select:hover{
    background-color: rgb(194, 194, 194);
}

.pcgameicon{
    text-align: center;
}

.pc_game_select{
    width: 150px;
    border: 1px solid black;
    border-radius: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.profile_regist_button{
    background-color: rgb(94, 88, 224);
    margin-left: 30%;
    margin-right: 30%;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
    border-radius: 10px;
    cursor:pointer

}

.profile_regist_button:hover{
    background-color: rgb(79,70,229);
}

/* モバイルかｐｃか選択画面 */
.mobile_select{
    background-color: rgb(231, 231, 231);
    display: flex;
    border: 1px solid rgb(206, 206, 206);
    /* width: 40vh; */
    margin-bottom: 30px;
    border-radius: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.mobile_select:hover{
    background-color: rgb(204, 204, 204);
}

.pc_select{
    background-color: rgb(231, 231, 231);
    display: flex;
    border: 1px solid rgb(206, 206, 206);
    /* width: 40vh; */
    border-radius: 20px;

}

.pc_select:hover{
    background-color: rgb(204, 204, 204);

}

.sumahoge-mu{
    margin: 0;
    margin-top: 8px;
    margin-left: 10px;
}

.pcgamenado{
    margin-left: 10px;
    margin-right: 10px;

}

.mobile_suggest_game_info{
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.mobile_suggest_game_info:hover{
    background-color: rgb(219, 219, 219);
}

/* .mobile_suggest_game_info{
    margin-top: 30px;
} */

.regist_button_mobile_appimg{
    border-radius: 10px;
    width: 50px;
    height: 50px;
    margin-right: 30px;
}

/* .regist_button_mobile_gametitle{
    margin-left: 20px;
    margin-top: 34px;
} */

.regist_button_game_select{
    margin-top: 30px;
}
.dialog_title{
    display: flex;
}

.regist_button_back_button{
    margin-top: 1px;
    margin-right: 10px;
}
.back_button{
    padding: 2px 2px;
}
.back_button:hover{
    background-color: rgb(224, 224, 224);
    border-radius: 50%;
}

.autocomplete_box{
    display: flex;
    align-items: center;

}
.autocomplete_img{
    width: 40px;
    height: 40px;
}
.autocomplete_label{
    margin-left: 10px;
}
.kurukuru{
    text-align: center;
}

