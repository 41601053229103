.id_regist{
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    color: white;
    margin-top: 2.5px;
    margin-bottom: 2.5px;
    border-radius: 20px;
}
.consumergame_name{
    margin-left: 10px;
}

.playstation{
    background-color: rgb(14, 99, 197);
}
.xbox{
    background-color: rgb(9, 160, 29);
}
.steam{
    background-color: rgb(15, 81, 124);
}
.switch{
    background-color: rgb(255, 0, 0);
}

.playstation:hover{
    background-color: rgb(13, 79, 155);
}
.xbox:hover{
    background-color: rgb(6, 107, 20);
}
.steam:hover{
    background-color: rgb(9, 50, 77);
}
.switch:hover{
    background-color: rgb(190, 4, 4);
}