.NewHome_body{
    width: 600px;
    /* background-color: rgb(255, 255, 255); */
    /* max-width: 600px; */
  border-left: 0.5px solid rgb(197, 197, 197);
  border-right: 0.5px solid rgb(197, 197, 197);
  min-height: 100vh;
}
.pagetitle{
    margin-left: 20px;
}
a{
    text-decoration: none;
}
.home_user_img{
    width: 60px;
    height: 60px;
    border-radius: 40px;
}
.friend_search_result{
    display: flex;
    margin-right: 12px;
    margin-left: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    display: flex;
    align-items: center;
}

.usersinfo:hover{
    background-color: rgb(218, 218, 218);
}
.username{
    margin-left: 20px;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: black;
    width: 370px;
}
.username:hover{
    text-decoration: underline;
}

.home_follow_delete_button{
    background-color: black;
    color: white;
    border-radius: 20px;
}
.new_profile_follow_deletebutton{
    margin-left: auto; /* この要素を右端に寄せる */
}

.home_follow_delete_button:hover{
    background-color: rgb(180, 180, 180);
}
.newhome_nouser{
    text-align: center;
}

@media screen and (max-width: 500px) {
    .NewHome_body{
        width: 100vh;
    }
    .username{
        width: 150px;
    }
} 
