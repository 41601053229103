/* .icon{
    margin-top: 20px;
}
.icon:hover{
    color: rgb(192, 192, 192);
} */

.sns_div{
    display: flex;
}

.crop_img_img{
    width: 120px;
    border-radius: 70px;
    margin-top: 30px
    
}

.crop_img_img:hover{
    opacity:0.7;

    width: 120px;
    border-radius: 70px;
    margin-top: 30px;
    color: rgb(192, 192, 192);
}

.edit_profile_crop_img{
    width: 120px;
    border-radius: 70px;
    margin-top: 30px;
    color: rgb(192, 192, 192);
}

.edit_profile_button{
    padding: 10px 20px;
    font-size: 12px;
    background-color: rgb(0, 0, 0);
    color: white;
    border-radius: 40px;
    cursor:pointer
}

.edit_profile_button:hover{
    background-color: rgb(64, 64, 64);
}



