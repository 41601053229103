.icon{
    font-size: 23px;
    padding: 7px 10px;
    margin: 0px 5px;
    background-color: gray;
    color: white;
    text-align: center;
    border-radius: 10px;
    cursor:pointer

}

.twitch { background-color:rgb(153, 78, 223)
}
.discord { background-color: rgb(110, 89, 207);}
.youtube { background-color: rgb(247, 7, 7);}
.x-twitter { background-color: rgb(0, 0, 0);}
.tiktok { background-color: rgb(0, 0, 0);}
.instagram { background-color: rgb(233, 52, 188);}

.twitch :hover{ background-color:rgb(108, 54, 158)}
.discord :hover{ background-color: rgb(75, 60, 141);}
.youtube :hover{ background-color: rgb(150, 0, 0);}
.x-twitter :hover{ background-color: rgb(73, 73, 73);}
.tiktok :hover{ background-color: rgb(71, 71, 71);}