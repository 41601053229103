.Setting{
    border-left: 0.5px solid rgb(197, 197, 197);
    border-right: 0.5px solid rgb(197, 197, 197);
}
.setting_body{
    width: 600px;
    /* text-align: center; */

}

.newprofile_button{
    border-top: 0.5px solid rgb(197, 197, 197);
    border-bottom: 0.5px solid rgb(197, 197, 197);

    display: flex;
    align-items: center;
    padding-top: 7px;
    padding-bottom: 7px;
    cursor:pointer

}
.newprofile_button:hover{
    background-color: rgb(218, 218, 218);
}
.newsetting_icon{
    margin-left: 20px;
    margin-right: 20px;
}

.language_button{
    margin-left: 100px;
    margin-right: 100px;
    padding:20px 10px;
    border: 0.5px solid black;
}
.change_id_content{
    margin-top: 10px;
}
.newsetting_account_delete_button{
color: red;}
@media screen and (max-width: 500px) {
    .Setting{
        width: 100vh;
    }
    .setting_body{
        width: 100%;
        /* text-align: center; */
    
    }
}