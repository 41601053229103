.riyoukiyaku_text{
    margin-top: 70px;
    margin-bottom: 30px;
    margin-right: 20px;
    margin-left: 20px;
}

.riyoukiyaku{
    display: flex;
    justify-content: center;
}