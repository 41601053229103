.login_header_buttons{
  display: block;
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  background-color: rgb(255, 255, 255);
  border-top: 1px solid rgb(228, 228, 228);
  z-index: 2;
  background-color: rgb(199, 199, 199);
  align-items: center;
  justify-content: space-between;
}
.idnest{
  color:white;
}
.login_body{
  margin-top: 60px;
    display: flex;
    justify-content: center;
    font-family: Arial, sans-serif;
    text-align: center;
  }
  .login{
    max-width: 430px;
  }
/* mailログイン */
.loginbutton{
    background-color: rgb(77, 211, 171);
    padding: 15px 10px;
    color: white;
    cursor:pointer
}
.loginbutton:hover{
    background-color: rgb(60, 170, 137);
}
.mataha{
    display: flex;
    align-items: center;
    justify-content: center;
}

.mataha:before,
.mataha:after {
  border-top: 1px solid black;
  content: "";
  width: 30%; 
}
.mataha:before {
  margin-right: 10px; 
}
.mataha:after {
  margin-left: 10px; 
}
.regist_button{
    background-color: rgb(0, 0, 0);
    padding: 15px 10px;
    color: white;
    cursor:pointer
  }

.footer{
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid rgb(228, 228, 228);
  z-index: 2;
  background-color: rgb(199, 199, 199);
  align-items: center;}
.remail{
  cursor:pointer;
  color: rgb(83, 164, 179);
}
.forget_password{
  cursor:pointer;
  color: rgb(83, 164, 179);

}
.mobile_footer{
  display: none;
}

.language{
  justify-content: space-between;
  width: 100%;
  display: flex;
  cursor:pointer
}
.footer-links{
  width: 100%;
  display: flex;
}
.language_select{
  margin-left: 10px;
  margin-right: 10px;
  color: rgb(255, 255, 255);
}

@media screen and (max-width: 500px) {
  .loginbutton{
    background-color: rgb(77, 211, 171);
    padding: 15px 10px;
    color: white;
    margin-left: 5%;
    margin-right: 5%;
  }
  .regist_button{
    background-color: rgb(0, 0, 0);
    padding: 15px 10px;
    color: white;
    margin-left: 5%;
    margin-right: 5%;
  }
  .footer{
    display: none;
  }
  /* .mobile_footer{
    display: block;
    background-color: rgb(212, 212, 212);
    margin-bottom: 0;
  } */

}
