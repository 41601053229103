.Search{
    border-left: 0.5px solid rgb(197, 197, 197);
    border-right: 0.5px solid rgb(197, 197, 197);
}
.search_body{
    width: 31px;

}


.search_user_img{
    width: 60px;
    height: 60px;
    border-radius: 40px;
    margin-left: 20px;
}
.search_result:hover{
    background-color: rgb(218, 218, 218);
}
.newprofile_tab2{
    border-left: 1px solid rgb(219, 219, 219);
}
.search_result{
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 5px;
}
.side_tab{
    margin-top: 120px;
}
/* 検索結果 */
.display_userinfo{
    margin-left: 20px;
}
.search_username{
    margin-top: 0px;
    margin-bottom: 0px;
}
.search_userid{
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 14px;
}
/* .search_body{
    max-width: 600px;
} */
/* 検索ゲーム */
.game_img{
    width: 60px;
    height: 60px;
    border-radius: 10px;
    margin-left: 20px;
}
.search_username{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 450px;

}
.search_userid{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 450px;
}
.usergames_gamename{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 450px;
}

.nogatti{
    text-align: center;
}
.usergames_gamename{
    color: black;
}
.kurukuru{
    margin-top: 30px;
    text-align: center;
}
@media screen and (max-width: 500px) {
    .Search{
        width: 100vh;
    }
    .search_body{
        width: 100%;
    }
    .search_username{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 200px;
        font-size: 15px;
    
    }
    .search_userid{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 200px;
    }
    .usergames_gamename{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 200px;
        font-size: 15px;
    }
    .search_user_img{
        width: 45px;
        height: 45px;
        border-radius: 40px;
        margin-left: 20px;
    }
    .game_img{
        width: 45px;
        height: 45px;
        border-radius: 10px;
        margin-left: 20px;
    }
    .pagetitle{
        margin-bottom: 0px;
    }
}

